<style lang="scss">
.profile-page {
    &__card {
      min-height: 375px;
    }

  .tabs {
    flex-basis: 89%;
    ul[role="tablist"] {
      padding: 0 !important;
    }

    a[role="tab"] {
      display: none;
    }
  }

  .custom-tab-nav {}
}
</style>
<!--eslint-disable-->
<template>
  <div class="profile-page">
    <b-card no-body class="mb-0 profile-page__card" v-if="profile">
      <section class="d-inline-flex">
        <div class="custom-tab-nav card-body pr-0">
          <b-button class="full-width" @click="tabs = $index" v-for="(item, $index) in tabItems" :key="$index" :variant="tabs === $index ? 'primary' : 'link'">{{item}}</b-button>
        </div>
        <b-tabs pills card vertical lazy v-model="tabs" class="">
          <b-tab key="basic-details">
            <profile-details @refresh="getProfile" :profile="profile"></profile-details>
          </b-tab>
          <b-tab key="security">
            <profile-security @refresh="getProfile"></profile-security>
          </b-tab>
        </b-tabs>
      </section>
    </b-card>

    <section v-if="!profile && !loading">
      <p>Profile details not found</p>
    </section>

    <b-modal id="modal-password-user" title="Change user password" hide-footer>
<!--      <change-password-user v-if="selectedUser" :user="selectedUser" @refresh="refreshUsers()" @close="closeModals()"></change-password-user>-->
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
  import ProfileService from '@/services/ProfileService';
  import ProfileDetails from '@/views/profile/sections/ProfileDetails.vue';
  import ProfileSecurity from '@/views/profile/sections/ProfileSecurity.vue';

  export default {
    name: 'Profile',
    components: {
      ProfileDetails,
      ProfileSecurity,
      // ChangePasswordUser,
    },
    props: {},
    data() {
      return {
        loading: false,
        profile: null,
        tabs: 0,
        tabItems: ['Your Details', 'Password'],
      };
    },
    mounted() {
      this.getProfile();
    },
    methods: {
      getProfile() {
        this.tabs = 0; // reset tabs
        this.loading = true;
        ProfileService.getProfile().then(res => {
          this.profile = res.data;
        }).catch(() => {
          this.$toast.error('Could not get profile, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        }).finally(() => {
          this.loading = false;
        });
      },
    },
  };
</script>
